const charge = {
  namespaced: true,
  state: {
    active: 0,
    orderDetail: {},
    isSettle: false,
    orderId: '',
    orderList: [],
    showBalance: false,
    orderMember: { id: 0, balance: 0, mobile: '', nickname: '' },
    goodsList: []
  },
  mutations: {
    synchronizationActive(state, payloud) {
      state.active = payloud
    },
    changeShowBalance(state, payloud) {
      state.showBalance = payloud
    },
    addGoods(state, payloud) {
      state.goodsList.push({ ...payloud, number: 1 })
    },
    removeGoods(state, payloud) {
      state.goodsList = state.goodsList.filter((item) => item.id !== payloud.id)
    },
    clearGoods(state) {
      state.goodsList = []
    },
    getOrderDetail(state, payloud) {
      state.showBalance = false
      state.orderDetail = payloud
      if (payloud.id) {
        state.orderId = payloud.id
        state.siteTableName = payloud.site_table_name
        state.orderMember = payloud.orderMember
        state.orderList = []
        state.orderList.push({ ...payloud, LcheckOrder: !(payloud.order_status === 7), random: Math.floor(Math.random() * 1000) })
        payloud.orderSplitList.forEach((item) => {
          state.orderList.push({ ...item, LcheckOrder: !(item.order_status === 7), random: Math.floor(Math.random() * 1000) })
        })
        state.isSettle = state.orderList.some(item => item.LcheckOrder)
      }
    },
    resetOrder(state) {
      state.tableDetail = {}
      state.orderDetail = {}
      state.siteTableName = ''
      state.orderId = ''
      state.orderList = []
      state.goodsList = []
      state.orderMember = { id: 0, balance: 0, mobile: '', nickname: '' }
    }
  }
}
export default charge