import { createStore } from 'vuex'
import user from "./module/user";
import takeOut from "./module/take-out"
import table from "./module/table"
import settle from "./module/settle"
import charge from "./module/charge"
import reserve from "./module/reserve"
import news from "./module/news";
import integration from "./module/integration";
export default createStore({
  modules: {
    user,
    takeOut,
    table,
    settle,
    charge,
    reserve,
    news,
    integration
  }
})
