const reserve = {
  namespaced: true,
  state: {
    active: 0,
    reserveOrder: {}
  },
  mutations: {
    synchronizationActive(state, payloud) {
      state.active = payloud
    },
    getReserveOrderDetails(state, payloud) {
      state.reserveOrder = payloud
    },
    resetOrder(state) {
      state.reserveOrder = {}
    }
  }
}

export default reserve