import { createRouter, createWebHashHistory } from 'vue-router'
import { getItem, setItem, removeItem } from "@/utils/storage";
import { RCP_TOKEN } from '@/store/module/index'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    redirect: '/layout/tables',
    component: () => import('@/views/layout/Layout.vue'),
    children: [
      {
        path: 'tables',
        name: 'tables',
        component: () => import('@/views/tables'),
      },
      {
        path: 'store',
        name: 'store',
        component: () => import('@/views/store'),
      },
      {
        path: 'assistant',
        name: 'assistant',
        component: () => import('@/views/assistant'),
      },
      {
        path: 'member',
        name: 'member',
        component: () => import('@/views/member'),
      },
      {
        path: 'chargeAccount',
        name: 'chargeAccount',
        component: () => import('@/views/chargeAccount'),
      },
      {
        path: 'settleAccounts',
        name: 'settleAccounts',
        component: () => import('@/views/settleAccounts'),
      },
      {
        path: 'shiftTurnover',
        name: 'shiftTurnover',
        component: () => import('@/views/shiftTurnover'),
      },
      // {
      //   path: 'inventoryManagement',
      //   name: 'inventoryManagement',
      //   component: () => import('@/views/inventoryManagement'),
      // },
      {
        path: 'commodityManagement',
        name: 'commodityManagement',
        component: () => import('@/views/commodityManagement'),
      },
      {
        path: 'meituan',
        name: 'meituan',
        component: () => import('@/views/meituan'),
      },
      // {
      //   path: 'tiktok',
      //   name: 'tiktok',
      //   component: () => import('@/views/tiktok'),
      // },
      {
        path: 'reserve',
        name: 'reserve',
        component: () => import('@/views/reserve'),
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/news'),
      },
      {
        path: 'scanCodePage',
        name: 'scanCodePage',
        component: () => import('@/views/scanCodePage'),
      },
      {
        path: 'integrationStore',
        name: 'integrationStore',
        component: () => import('@/views/integrationStore'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  let token = getItem(RCP_TOKEN);
  // console.log(token);
  if (to.name === 'login') {
    next()
  } else {
    if (token) {
      next()
    } else {
      router.push('/login')
    }
  }
})
export default router
