import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant, { Lazyload } from 'vant';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@vant/touch-emulator';
import 'animate.css';
import 'amfe-flexible'
import 'vant/lib/index.css';
import '@/assets/font/iconfont.css'
import * as signalR from "@microsoft/signalr";
// import * as Sentry from "@sentry/vue";
// import {version} from "@/utils/config"

const app = createApp(App)
// if (process.env.NODE_ENV === 'production') {

// Sentry.init({
//     app,
//     dsn: "https://27f2f2ed2df8bc7ec27038e96b8837d4@o4505502980374528.ingest.sentry.io/4506590776197120",
//     integrations: [
//         new Sentry.BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         }),
//         new Sentry.Replay(),
//     ],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,

//     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

//     // Capture Replay for 10% of all sessions,
//     // plus for 100% of sessions with an error
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     environment: process.env.NODE_ENV,
//     release: version
// });
// }


app.use(store).use(router).use(Vant).use(ElementPlus).use(Lazyload, { lazyComponent: true }).mount('#app')

// let connection = new signalR.HubConnectionBuilder()
//     .withUrl("https://sss.ficoo.vip/apiuidev/tools/notifyHub", {
//         accessTokenFactory: () => "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQtdHlwZSI6IjEiLCJ1c2VyLXR5cGUiOiIxIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiOCIsInJvbGUtaWQiOiI4IiwidGVuYW50LWlkIjoiMTg0MzM5ODE4ODI1NDI3NyIsIm5pY2tuYW1lIjoi5L2g5bCxIiwic2l0ZS1pZCI6IjE4NDM0NjY1ODkzODk5NTciLCJtb2JpbGUiOiIxODg4ODg4ODg4OCIsInNpZCI6IjE4NDkwNjc1ODMwMDMwMTMiLCJzdGFmZi1pZCI6IjAiLCJvcmctaWQiOiIxODQzNDY2NTg5Mzg5OTU3IiwidGVybS1pZCI6IjAiLCJyZWZyZXNoVG9rZW4iOiJERDkxdnc5ZThYcHoxc2JKT3B6ZmNUYUdvZjhpMjBtSUhPN1F5UlZjK0lBPSIsInJlZnJlc2hFeHBpcnlUaW1lIjoiMjAyNC80LzI0IOS4i-WNiDY6MjA6MDciLCJuZWVkQ2hlY2tUb2tlbiI6ImZhbHNlIiwiZXhwIjoxNzEzOTU0MDA3LCJpc3MiOiJ0ZXN0IiwiYXVkIjoiVXNlciJ9.YCNdxqxrpcddVa0QsdGvWzU_LuYtN9xqTQTesoCYsb0"
//     })
//     .withAutomaticReconnect()
//     .build();

// async function start() {
//     try {
//         await connection.start();
//         console.log("SignalR Connected.");
//     } catch (err) {
//         console.log(err);
//         setTimeout(start, 5000);
//     }
// }

// connection.on("ReceiveMessage", function (user, message) {
//     console.log(`${user} 发送消息：${message}`)
// });

// connection.onreconnecting(error => {
//     // 重新连接中
//     console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
// });

// connection.onreconnected(connectionId => {
//     // 重连成功
//     console.assert(connection.state === signalR.HubConnectionState.Connected);
//     // 发送消息加入到分组门店id

// });

// connection.onclose(async () => {
//     //连接失败
//     await start();
// })

// start()
